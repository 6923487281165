<template>
  <div class="py-3">
  <div class="d-flex flex-row-reverse mr-4 modifyBtn">
    <v-btn
      color="primary"
      outlined
      :disabled="allowUserToEditOrNot || editMode"
      @click="$emit('modifyDose')"
    >
      Modify
    </v-btn>
    </div>
     <v-container>
     <v-card>
    <div v-show="editMode" class="py-3 mb-n3 mt-6">
       <v-card-title class="title mt-n7 pt-1" >
          Dose Details
        </v-card-title>
        <!-- <div class="title mt-n10 pt-1 pl-1">
           Dose Details
          </div> -->
      <!-- <DosageInfo
        is-suggested
        :general-details="generalDetails"
        :dose-details="suggestedDoseDetails"
        v-on="$listeners"
      /> -->
      <v-form v-model="formValid">
        <v-layout row pa-4>
          <v-flex
            v-for="(field, index) in allFields"
            :key="index"
            xs12
            sm4
            lg4
            xl3
            pr-4
          >
          <component
            v-if="field.type === 'radio'"
            v-model="doseDetails[field.key]"
            :is="field.type"
            :append-text="field.unit"
            :label="field.label"
            :disabled="field.isReadonly"
            :field-key="field.key"
            :items="field.items"
          />
            <component
              :is="field.type"
              v-if="field.type === 'radio'"
              v-model="doseDetails[field.key]"
              :append-text="field.unit"
              :label="field.label"
              :disabled="field.isReadonly"
              :field-key="field.key"
              :items="field.items"
            />
            <component
              :is="field.type"
              v-else
              v-model="doseDetails[field.key]"
              :items="field.items"
              :allow-user-to-edit-or-not="allowUserToEditOrNot"
              :disabled="field.isReadonly || allowUserToEditOrNot"
              :validate="validate"
              :append-text="field.unit"
              :label="field.label"
              :field-key="field.key"
              :required="field.isRequired"
              :message="field.message"
              :error-message="field.errorMessage"
              :common-function="commonFunction"
              @change="onChange($event, field)"
            />

          </v-flex>
        </v-layout>
      </v-form>
      <!-- <hr /> -->
      <v-btn color="primary" class="mt-n11 mb-n5 ml-1" :loading="saving" @click="onRecalculate">
        Recalculate Metrics
      </v-btn>
    </div>
    </v-card>
     </v-container>
     

  </div>
</template>
<script>
import DosageInfo from "@/components/patient/DosageInfo";
import NumberInput from "@/components/form/NumberInput";
import Dropdown from "@/components/form/Dropdown";
import Number from "@/components/form/NumberInput";
import Radio from "@/components/form/Radio";

export default {
  name: "ModifyDosage",
  components: { Dropdown, DosageInfo, NumberInput, Number, Radio },
  props: {
    schema: { type: Array, required: true, default: () => [] },
    generalDetails: { type: Object, required: true, default: () => ({}) },
    doseDetails: { type: Object, required: true, default: () => ({}) },
    suggestedDoseDetails: { type: Object, required: true, default: () => ({}) },
    enableNavigation: { type: Boolean, required: true, default: true },
    saving: { type: Boolean, required: true, default: true },
    allowUserToEditOrNot: { type: Boolean, required: true, default: false },
  },
  data() {
    return {
      validate: false,
      allFields: [],
      formValid: false,
    };
  },
  computed: {
    editMode() {
      return !this.enableNavigation;
    },
    dailyDose() {
      return this.dosage(this.doseDetails);
    },
  },
  mounted() {
    this.allFields = this.schema;
  },
  methods: {
    onRecalculate() {
      this.validate = true;
      this.$emit("recalculate", this.formValid);
    },
    async action(v, key) {
      const valid = v.fields.every(
        (field) =>
          this.doseDetails[field] != undefined && this.doseDetails[field] != ""
      );
      if (valid) {
        this.commonFunction(v, key, "doseRecommendation");
      }
    },

    async onChange(value, field) {
      if (value) {
        if (field.validator != undefined) {
          field.validator.map((valid) => {
            if (valid.type == "dependent") {
              valid.fields.map((field) => {
                this.allFields.map((f) => {
                  if (f.key == field) {
                    f.validator.map((v) => {
                      if (v.type == "internal" || v.type == "external") {
                        this.commonFunction(v, field, "doseRecommendation");
                      }
                    });
                  } else {
                    if (f.validator != undefined) {
                      f.validator.map((v) => {
                        if (v.type == "internal" || v.type == "external") {
                          setTimeout(() => {
                            this.commonFunction(v, f.key, "doseRecommendation");
                          }, 500);
                        }
                      });
                    }
                  }
                });
              });
            } else {
              if (valid.type == "external") {
                this.commonFunction(valid, field, "doseRecommendation");
              }
            }
          });
        }
      }
    },
    commonFunction(v, key, type) {
      this.$emit("commonFunction", v, key, type);
    },
  },
};
</script>
<style scoped>
hr {
  opacity: 0.1;
}
.modifyBtn { 
  /* margin-top: -115px; */
  margin-top:-1.8em;
 } 
 .title{
   background-color:#808080;
   height:2em;
   color: white;
 }
.v-application .py-3 {
    /* padding-top: 12px!important; */
    padding-bottom: 0px!important;
}
</style>
