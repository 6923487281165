const display_message = (message) => {
    /* eslint-disable */
    //   console.log(process.env,"env");
    if (["dev","local","test"].includes(process.env.VUE_APP_ENV)) {
        console.log(message,"messages");
      return message;
    }
    return "Please contact the administrator";
  };
  
  export default {
    display_message,
  };
  